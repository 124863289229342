import React from 'react'
import EngineeringTemplate from 'components/templates/EngineeringTemplate/EngineeringTemplate'

export default function EngineeringConstructionSupervisionPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Direzione lavori'

  const description =
    'Il <b>Direttore dei Lavori</b> è la figura professionale scelta dal committente con lo scopo di seguire l’andamento regolare del cantiere.<br> Assicuriamo la giusta professionalità per svolgere tutti i compiti del Direttore dei Lavori: <ol><li>La redazione dei SAL (stato avanzamento lavori) o, se redatti dall’impresa costruttrice, il controllo e l’avallo di questi ultimi. Nei lavori privati le mansioni svolte dal Direttore dei Lavori nel controllo dei SAL possono essere di natura “quantitativa” (computi delle opere eseguite) e non di natura “finanziaria” (accordi privati che possono essere riservati a committente – impresa costruttrice);</li> <li>La vidimazione di eventuali modifiche tecniche migliorative del progetto;</li> <li>La verifica della corretta esecuzione dei lavori;</li> <li>La stesura dei verbali di riunione e di eventuali ordini di servizio;</li> <li>Il rilascio di eventuali certificati che possono essere quelli di corretta posa in opera, di corretta esecuzione dei lavori o altri previsti dalla legge.</li></ol>'

  return (
    <>
      <EngineeringTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
